<template>
  <div class="home">
      <button @click="submit()">高考模拟</button>
       <!-- <form  name="form"
            id="form"
            action="http://casteacher.cytest001.com/login?service=http%3A%2F%2Fteacher.cytest001.com%2F777c15f4aabdab52&targetUrl=http%3A%2F%2Fteacher.cytest001.com%2F777c15f4aabdab52"
            method="post">
        <input type="hidden" value="submit"> 
       </form> -->
  </div>
</template>

<script>
export default {
    data(){
        return {}
    },
    methods:{
        submit(){
            let url = 'http://casteacher.cytest001.com/login?service=http%3A%2F%2Fteacher.cytest001.com%2F777c15f4aabdab52&targetUrl=http%3A%2F%2Fteacher.cytest001.com%2F777c15f4aabdab52';
            //  let form = document.getElementById('form')
            //  form.submit();
            console.log(url);
            let temp = document.createElement('form');
            temp.action = url;  
            temp.method = "post";

            let username = document.createElement("input");        
            let password = document.createElement("input");        
            let lt = document.createElement("input");        
            let execution = document.createElement("input");        
            let _eventId = document.createElement("input");   
            
            username.name = 'username';
            username.type = 'hidden';
            password.name = 'password';
            password.type = 'hidden';
            lt.name = 'lt';
            lt.type = 'hidden';
            execution.name = 'execution';
            execution.type = 'hidden';
            _eventId.name = '_eventId';
            _eventId.type = 'hidden';
            

            username.value = 'wanggang';
            
            password.value = 'chuanyang';
           
            lt.value = 'LT-5-oaSn15PbQ9sTfBzQjKNHrbJ0rZfuy6-casteacher1';
            
            execution.value = 'e4s1';
           
            _eventId.value = 'submit';
            

            temp.appendChild(username);        
            temp.appendChild(password);        
            temp.appendChild(lt);        
            temp.appendChild(execution);        
            temp.appendChild(_eventId);        

            document.body.appendChild(temp);
            temp.submit();
            return temp;
        }
    }
}
</script>

<style>

</style>